import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Table } from 'react-bootstrap';

import '../../generic-page.scss';

const CSPVPGearingPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>PVP Gearing</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/category_pvpgearing.png"
            alt="PVP Gearing"
          />
        </div>
        <div className="page-details">
          <h1>PVP Gearing</h1>
          <h2>A simple guide about gearing in PVP.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          As gearing in PVP is really complicated and depends on the teams
          you're running, this guide will only explain the archetypes for
          gearing.
        </p>
        <p>
          Also, while the guide just uses the images for Counters gear, the
          guide applies to all types of characters.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
        <SectionHeader title="PVP Gearing" />
        <p>There are five main kinds of PvP gear:</p>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_1.webp"
          alt="PVP Gear"
        />
        <h5>CDR DPS</h5>
        <ul>
          <li>Set - CDR</li>
          <li>Substats - Ground Damage, Skill Haste</li>
        </ul>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_1.webp"
          alt="PVP Gear"
        />
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_2.webp"
          alt="PVP Gear"
        />
        <h5>Autoattack DPS</h5>
        <ul>
          <li>Set - ATK, ASPD, Any Spectral (Blaze Preferred)</li>
          <li>
            Substats - Ground Damage on EVERY piece, Striker/Defender Damage on
            2nd lines.
          </li>
        </ul>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_3.webp"
          alt="PVP Gear"
        />
        <h5>Full Tank</h5>
        <ul>
          <li>
            Set - HP or DEF. On most units DEF is worse by a percent or two, but
            it’s still a good option.
          </li>
          <li>
            Inhibitor Weapons are mandatory because in PvP no set no latent
            Inhibs are better than any other alternative on frontliners.
          </li>
          <li>
            Substats - Ground Res on EVERY piece, Melee/Ranged/Counter Res on
            2nd lines and latents. CDMG RES on maze armor. Evasion accessories
            whenever possible, but melee res Gordias ones are alright too.
          </li>
        </ul>
        <h5>CDR/ASPD Tank</h5>
        <ul>
          <li>Set - CDR/ASPD set</li>
          <li>
            Inhibitor Weapons are mandatory because in PvP no set no latent
            Inhibs are better than any other alternative on frontliners
          </li>
          <li>
            Substats - Ground Res on EVERY piece, Melee/Ranged/Counter Res on
            2nd lines and latents. CDMG RES on maze armor. Evasion accessories
            whenever possible, but melee res Gordias ones are alright too.
          </li>
          <li>This is a niche set that only is used in certain scenarios.</li>
        </ul>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_4.webp"
          alt="PVP Gear"
        />
        <h5>Defensive CDR</h5>
        <ul>
          <li>Set - CDR</li>
          <li>
            Substats - Skill haste on EVERY piece. Ground/Counter/Melee/Ranged
            RES on 2nd lines.
          </li>
        </ul>
        <SectionHeader title="Relic Gear" />
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_5.png"
          alt="PVP Gear"
        />
        <h5>Britra</h5>
        <p>
          Good DPS gear. Mostly used for Melee/Ranged damage latent rolls.
          Counter Evasion main stat accessories are especially important because
          they allow you to go above evasion thresholds.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Useful Latent</th>
              <th>Set</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ranged Damage</td>
              <td>ASPD, ATK, CDR</td>
              <td>Usable on most DPS units.</td>
            </tr>
            <tr>
              <td>Melee Damage</td>
              <td>ASPD, ATK, CDMG</td>
              <td>Niche, mostly used by AMina, RElizabeth and terror</td>
            </tr>
            <tr>
              <td>ATK%, CDMG%</td>
              <td>ASPD, ATK, CDMG</td>
              <td>
                Placeholder gear until you get melee/ranged damage pieces.
              </td>
            </tr>
          </tbody>
        </Table>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_6.png"
          alt="PVP Gear"
        />
        <h5>Inhibitor</h5>
        <p>
          Used as tank gear. Evasion Accessories are much better than hit ones.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Useful Latent</th>
              <th>Set</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Melee / Ranged DMG RES</td>
              <td>HP, DEF, CDR, ASPD</td>
              <td>
                BIS Tank latents. Ranged DMG RES is preferred over melee on most
                tanks. Mostly forward deployment units truly benefit from Melee
                Res.
              </td>
            </tr>
            <tr>
              <td>Skill Haste</td>
              <td>CDR</td>
              <td>
                Mostly used for weapons. Mixing ground res Inhibitor and Swift
                relics allows for very tanky units that still have close to
                maximum skill haste.
              </td>
            </tr>
            <tr>
              <td>Skill Haste</td>
              <td>HP, DEF</td>
              <td>Usually worse than above.</td>
            </tr>
            <tr>
              <td>HP%, CDMG RES%</td>
              <td>HP, DEF, CDR, ASPD</td>
              <td>
                Placeholder until you get better pieces. CDMG RES is actually
                strong on low evasion units, but those aren’t really viable in
                PvP.
              </td>
            </tr>
          </tbody>
        </Table>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_7.png"
          alt="PVP Gear"
        />
        <h5>Swift</h5>
        <p>
          Better maze gear if you get a decent skill haste latent roll. ASPD
          latent sees very niche use. As is with any relic gear evasion
          accessories are incredibly important
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Useful Latent</th>
              <th>Set</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Skill Haste</td>
              <td>CDR</td>
              <td>
                Usable by any skill-haste based unit. Substats depend on the
                unit in question.
              </td>
            </tr>
            <tr>
              <td>ASPD</td>
              <td>ASPD</td>
              <td>
                Agnes, Acolytes (worse than jungle), AHorizon, middling AMina
                builds.
              </td>
            </tr>
            <tr>
              <td>Movement Speed</td>
              <td>HP, DEF</td>
              <td>Maestra gear</td>
            </tr>
          </tbody>
        </Table>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_8.webp"
          alt="PVP Gear"
        />
        <h5>Jungle</h5>
        <p>
          Also known as Wild Relic Gear. This is endgame gear with relatively
          low farming priority. Used as 2pc set on frontliners, some healers can
          run full healing enhancement build. Tanks can’t use Wild weapons
          because it lacks Ground DMG RES substat, so Inhibitor is still
          mandatory. With the new overtime system there is less reason to farm
          Jungle for frontliner gear, but it’s still good for some healers.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Useful Latent</th>
              <th>Set</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Incoming Healing+</td>
              <td>Incoming Healing+, Melee/Ranged/Ground DMG Res</td>
              <td>
                Frontliner gear, incredible on self-healing units. Most notable
                example is Mors.
              </td>
            </tr>
            <tr>
              <td>HP% / CDMG RES%</td>
              <td>Incoming Healing, Melee/Ranged/Ground DMG Res</td>
              <td>Placeholder frontliner gear.</td>
            </tr>
            <tr>
              <td>Healing Enhancement+</td>
              <td>Healing Enhancement+</td>
              <td>
                Non-skill haste based healers. Right now that’s Ironside,
                Acolytes and Claudia.
              </td>
            </tr>
            <tr>
              <td>Healing Enhancement+</td>
              <td>ASPD</td>
              <td>
                Acolyte only gear that’s tiny bit worse than full Healing
                Enchancement gear.
              </td>
            </tr>
          </tbody>
        </Table>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_9.webp"
          alt="PVP Gear"
        />
        <h5>Volcano</h5>
        <p>
          Also known as Flame Relic Gear. This is endgame gear with relatively
          low farming priority. Can be used on most units that don’t rely on
          skill haste. The best set for Volcano gear is Ground DMG, but ASPD2,
          Special DMG AMP and Cooldown2 see some niche use.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Useful Latent</th>
              <th>Set</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Anti-Counter DMG</td>
              <td>Any DPS Set</td>
              <td>
                Highest DPS gear in the game, provided that week is mostly
                counter frontline.
              </td>
            </tr>
            <tr>
              <td>Special DMG AMP</td>
              <td>Any DPS Set</td>
              <td>Extremely niche. </td>
            </tr>
            <tr>
              <td>ATK% / ASPD / CDMG</td>
              <td>Any DPS Set</td>
              <td>Placeholders for actually good volcano pieces.</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </DashboardLayout>
  );
};

export default CSPVPGearingPage;

export const Head: React.FC = () => (
  <Seo
    title="PVP Gearing | Counter Side | Prydwen Institute"
    description="A simple guide about gearing in PVP."
    game="cs"
  />
);
